import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import Layout from '@layout';

interface Props {
  course?: boolean;
}

const NotFound: FunctionComponent<Props> = (props: Props) => {
  const { course } = props;
  const { t } = useTranslation('404');

  return (
    <Layout course={course} pageTitle="Algobash Recruiter - 404 Not Found">
      <div className={clsx('pb-10 pt-32 px-6 h-screen flex flex-col items-center justify-center', {
        'bg-blue': !course,
        'bg-blue-semidark': course,
      })}
      >
        <div className="flex flex-col items-center justify-center max-w-xl">
          <h4 className="text-6xl font-semibold text-lime-light">404</h4>
          <h5 className="text-white text-2xl font-semibold">{t('title')}</h5>
          <p className="text-white text-lg text-center py-3">{t('subTitle')}</p>
          <br />
          <Link href="/" passHref>
            <a className="rounded px-8 py-4 font-medium hover:opacity-80 text-sm bg-blue-dark text-white">
              {t('button')}
            </a>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
