import React from 'react';
import { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import NotFound from '@component/NotFound';

const NotFoundPage: NextPage = () => (
  <NotFound course />
);

export const getStaticProps: GetStaticProps = async ({ locale = 'en' }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', '404'])),
  },
});

export default NotFoundPage;
